.snow {
  position: fixed;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
}
.snow:nth-child(1) {
  opacity: 0.86;
  transform: translate(99.4994vw, -10px) scale(0.1598);
  animation: fall-1 25s -28s linear infinite;
}
@keyframes fall-1 {
  76.053% {
    transform: translate(96.0231vw, 76.053vh) scale(0.1598);
  }
  to {
    transform: translate(97.76125vw, 100vh) scale(0.1598);
  }
}
.snow:nth-child(2) {
  opacity: 0.9841;
  transform: translate(97.9462vw, -10px) scale(0.8967);
  animation: fall-2 23s -24s linear infinite;
}
@keyframes fall-2 {
  48.993% {
    transform: translate(104.4926vw, 48.993vh) scale(0.8967);
  }
  to {
    transform: translate(101.2194vw, 100vh) scale(0.8967);
  }
}
.snow:nth-child(3) {
  opacity: 0.6282;
  transform: translate(89.1248vw, -10px) scale(0.4162);
  animation: fall-3 11s -18s linear infinite;
}
@keyframes fall-3 {
  79.572% {
    transform: translate(93.5291vw, 79.572vh) scale(0.4162);
  }
  to {
    transform: translate(91.32695vw, 100vh) scale(0.4162);
  }
}
.snow:nth-child(4) {
  opacity: 0.6272;
  transform: translate(98.0674vw, -10px) scale(0.9343);
  animation: fall-4 12s -5s linear infinite;
}
@keyframes fall-4 {
  70.171% {
    transform: translate(99.4731vw, 70.171vh) scale(0.9343);
  }
  to {
    transform: translate(98.77025vw, 100vh) scale(0.9343);
  }
}
.snow:nth-child(5) {
  opacity: 0.4015;
  transform: translate(90.0838vw, -10px) scale(0.5119);
  animation: fall-5 23s -29s linear infinite;
}
@keyframes fall-5 {
  74.72% {
    transform: translate(97.0279vw, 74.72vh) scale(0.5119);
  }
  to {
    transform: translate(93.55585vw, 100vh) scale(0.5119);
  }
}
.snow:nth-child(6) {
  opacity: 0.8213;
  transform: translate(55.9123vw, -10px) scale(0.0392);
  animation: fall-6 20s -30s linear infinite;
}
@keyframes fall-6 {
  65.169% {
    transform: translate(54.6896vw, 65.169vh) scale(0.0392);
  }
  to {
    transform: translate(55.30095vw, 100vh) scale(0.0392);
  }
}
.snow:nth-child(7) {
  opacity: 0.4674;
  transform: translate(0.4494vw, -10px) scale(0.6024);
  animation: fall-7 12s -25s linear infinite;
}
@keyframes fall-7 {
  30.972% {
    transform: translate(-2.6777vw, 30.972vh) scale(0.6024);
  }
  to {
    transform: translate(-1.11415vw, 100vh) scale(0.6024);
  }
}
.snow:nth-child(8) {
  opacity: 0.3193;
  transform: translate(77.172vw, -10px) scale(0.6551);
  animation: fall-8 16s -4s linear infinite;
}
@keyframes fall-8 {
  44.564% {
    transform: translate(77.9869vw, 44.564vh) scale(0.6551);
  }
  to {
    transform: translate(77.57945vw, 100vh) scale(0.6551);
  }
}
.snow:nth-child(9) {
  opacity: 0.9765;
  transform: translate(31.6873vw, -10px) scale(0.1322);
  animation: fall-9 18s -15s linear infinite;
}
@keyframes fall-9 {
  48.627% {
    transform: translate(25.1549vw, 48.627vh) scale(0.1322);
  }
  to {
    transform: translate(28.4211vw, 100vh) scale(0.1322);
  }
}
.snow:nth-child(10) {
  opacity: 0.0387;
  transform: translate(43.1642vw, -10px) scale(0.4085);
  animation: fall-10 24s -28s linear infinite;
}
@keyframes fall-10 {
  64.352% {
    transform: translate(47.74vw, 64.352vh) scale(0.4085);
  }
  to {
    transform: translate(45.4521vw, 100vh) scale(0.4085);
  }
}
.snow:nth-child(11) {
  opacity: 0.3712;
  transform: translate(53.9422vw, -10px) scale(0.3867);
  animation: fall-11 11s -3s linear infinite;
}
@keyframes fall-11 {
  33.495% {
    transform: translate(57.2446vw, 33.495vh) scale(0.3867);
  }
  to {
    transform: translate(55.5934vw, 100vh) scale(0.3867);
  }
}
.snow:nth-child(12) {
  opacity: 0.3126;
  transform: translate(72.4561vw, -10px) scale(0.713);
  animation: fall-12 12s -21s linear infinite;
}
@keyframes fall-12 {
  50.948% {
    transform: translate(76.9vw, 50.948vh) scale(0.713);
  }
  to {
    transform: translate(74.67805vw, 100vh) scale(0.713);
  }
}
.snow:nth-child(13) {
  opacity: 0.5125;
  transform: translate(94.6517vw, -10px) scale(0.2296);
  animation: fall-13 28s -30s linear infinite;
}
@keyframes fall-13 {
  46.97% {
    transform: translate(85.5949vw, 46.97vh) scale(0.2296);
  }
  to {
    transform: translate(90.1233vw, 100vh) scale(0.2296);
  }
}
.snow:nth-child(14) {
  opacity: 0.4227;
  transform: translate(1.9588vw, -10px) scale(0.3327);
  animation: fall-14 15s -8s linear infinite;
}
@keyframes fall-14 {
  59.524% {
    transform: translate(-3.9936vw, 59.524vh) scale(0.3327);
  }
  to {
    transform: translate(-1.0174vw, 100vh) scale(0.3327);
  }
}
.snow:nth-child(15) {
  opacity: 0.0078;
  transform: translate(48.9011vw, -10px) scale(0.368);
  animation: fall-15 21s -13s linear infinite;
}
@keyframes fall-15 {
  63.253% {
    transform: translate(49.3002vw, 63.253vh) scale(0.368);
  }
  to {
    transform: translate(49.10065vw, 100vh) scale(0.368);
  }
}
.snow:nth-child(16) {
  opacity: 0.7371;
  transform: translate(60.5096vw, -10px) scale(0.5512);
  animation: fall-16 29s -15s linear infinite;
}
@keyframes fall-16 {
  69.013% {
    transform: translate(51.0004vw, 69.013vh) scale(0.5512);
  }
  to {
    transform: translate(55.755vw, 100vh) scale(0.5512);
  }
}
.snow:nth-child(17) {
  opacity: 0.8256;
  transform: translate(21.9542vw, -10px) scale(0.2171);
  animation: fall-17 20s -14s linear infinite;
}
@keyframes fall-17 {
  49.707% {
    transform: translate(29.8127vw, 49.707vh) scale(0.2171);
  }
  to {
    transform: translate(25.88345vw, 100vh) scale(0.2171);
  }
}
.snow:nth-child(18) {
  opacity: 0.8055;
  transform: translate(97.6568vw, -10px) scale(0.8659);
  animation: fall-18 22s -21s linear infinite;
}
@keyframes fall-18 {
  73.848% {
    transform: translate(98.302vw, 73.848vh) scale(0.8659);
  }
  to {
    transform: translate(97.9794vw, 100vh) scale(0.8659);
  }
}
.snow:nth-child(19) {
  opacity: 0.4694;
  transform: translate(51.2199vw, -10px) scale(0.8946);
  animation: fall-19 27s -29s linear infinite;
}
@keyframes fall-19 {
  46.101% {
    transform: translate(46.4149vw, 46.101vh) scale(0.8946);
  }
  to {
    transform: translate(48.8174vw, 100vh) scale(0.8946);
  }
}
.snow:nth-child(20) {
  opacity: 0.5462;
  transform: translate(75.5382vw, -10px) scale(0.5456);
  animation: fall-20 11s -20s linear infinite;
}
@keyframes fall-20 {
  70.415% {
    transform: translate(74.1307vw, 70.415vh) scale(0.5456);
  }
  to {
    transform: translate(74.83445vw, 100vh) scale(0.5456);
  }
}
.snow:nth-child(21) {
  opacity: 0.201;
  transform: translate(79.3693vw, -10px) scale(0.7707);
  animation: fall-21 16s -6s linear infinite;
}
@keyframes fall-21 {
  56.365% {
    transform: translate(73.6133vw, 56.365vh) scale(0.7707);
  }
  to {
    transform: translate(76.4913vw, 100vh) scale(0.7707);
  }
}
.snow:nth-child(22) {
  opacity: 0.9424;
  transform: translate(53.8921vw, -10px) scale(0.2053);
  animation: fall-22 25s -14s linear infinite;
}
@keyframes fall-22 {
  56.65% {
    transform: translate(47.5666vw, 56.65vh) scale(0.2053);
  }
  to {
    transform: translate(50.72935vw, 100vh) scale(0.2053);
  }
}
.snow:nth-child(23) {
  opacity: 0.7192;
  transform: translate(64.3667vw, -10px) scale(0.7436);
  animation: fall-23 10s -27s linear infinite;
}
@keyframes fall-23 {
  32.72% {
    transform: translate(62.747vw, 32.72vh) scale(0.7436);
  }
  to {
    transform: translate(63.55685vw, 100vh) scale(0.7436);
  }
}
.snow:nth-child(24) {
  opacity: 0.4346;
  transform: translate(63.7966vw, -10px) scale(0.7394);
  animation: fall-24 27s -8s linear infinite;
}
@keyframes fall-24 {
  52.029% {
    transform: translate(66.7218vw, 52.029vh) scale(0.7394);
  }
  to {
    transform: translate(65.2592vw, 100vh) scale(0.7394);
  }
}
.snow:nth-child(25) {
  opacity: 0.8444;
  transform: translate(93.2247vw, -10px) scale(0.9388);
  animation: fall-25 16s -27s linear infinite;
}
@keyframes fall-25 {
  63.987% {
    transform: translate(100.9756vw, 63.987vh) scale(0.9388);
  }
  to {
    transform: translate(97.10015vw, 100vh) scale(0.9388);
  }
}
.snow:nth-child(26) {
  opacity: 0.3636;
  transform: translate(68.8557vw, -10px) scale(0.0665);
  animation: fall-26 11s -10s linear infinite;
}
@keyframes fall-26 {
  73.71% {
    transform: translate(69.8577vw, 73.71vh) scale(0.0665);
  }
  to {
    transform: translate(69.3567vw, 100vh) scale(0.0665);
  }
}
.snow:nth-child(27) {
  opacity: 0.8151;
  transform: translate(70.8791vw, -10px) scale(0.9028);
  animation: fall-27 30s -19s linear infinite;
}
@keyframes fall-27 {
  30.381% {
    transform: translate(72.4583vw, 30.381vh) scale(0.9028);
  }
  to {
    transform: translate(71.6687vw, 100vh) scale(0.9028);
  }
}
.snow:nth-child(28) {
  opacity: 0.0973;
  transform: translate(23.4072vw, -10px) scale(0.3815);
  animation: fall-28 28s -9s linear infinite;
}
@keyframes fall-28 {
  54.19% {
    transform: translate(15.0585vw, 54.19vh) scale(0.3815);
  }
  to {
    transform: translate(19.23285vw, 100vh) scale(0.3815);
  }
}
.snow:nth-child(29) {
  opacity: 0.1369;
  transform: translate(94.7252vw, -10px) scale(0.9816);
  animation: fall-29 23s -13s linear infinite;
}
@keyframes fall-29 {
  50.263% {
    transform: translate(97.1284vw, 50.263vh) scale(0.9816);
  }
  to {
    transform: translate(95.9268vw, 100vh) scale(0.9816);
  }
}
.snow:nth-child(30) {
  opacity: 0.6866;
  transform: translate(44.3375vw, -10px) scale(0.4499);
  animation: fall-30 17s -15s linear infinite;
}
@keyframes fall-30 {
  34.701% {
    transform: translate(53.2986vw, 34.701vh) scale(0.4499);
  }
  to {
    transform: translate(48.81805vw, 100vh) scale(0.4499);
  }
}
.snow:nth-child(31) {
  opacity: 0.9658;
  transform: translate(64.0299vw, -10px) scale(0.5037);
  animation: fall-31 26s -11s linear infinite;
}
@keyframes fall-31 {
  64.519% {
    transform: translate(55.7382vw, 64.519vh) scale(0.5037);
  }
  to {
    transform: translate(59.88405vw, 100vh) scale(0.5037);
  }
}
.snow:nth-child(32) {
  opacity: 0.7987;
  transform: translate(35.0012vw, -10px) scale(0.3258);
  animation: fall-32 17s -11s linear infinite;
}
@keyframes fall-32 {
  64.353% {
    transform: translate(44.6596vw, 64.353vh) scale(0.3258);
  }
  to {
    transform: translate(39.8304vw, 100vh) scale(0.3258);
  }
}
.snow:nth-child(33) {
  opacity: 0.4553;
  transform: translate(18.8985vw, -10px) scale(0.0092);
  animation: fall-33 24s -13s linear infinite;
}
@keyframes fall-33 {
  55.031% {
    transform: translate(10.0791vw, 55.031vh) scale(0.0092);
  }
  to {
    transform: translate(14.4888vw, 100vh) scale(0.0092);
  }
}
.snow:nth-child(34) {
  opacity: 0.7451;
  transform: translate(98.6979vw, -10px) scale(0.8842);
  animation: fall-34 12s -21s linear infinite;
}
@keyframes fall-34 {
  52.48% {
    transform: translate(92.9072vw, 52.48vh) scale(0.8842);
  }
  to {
    transform: translate(95.80255vw, 100vh) scale(0.8842);
  }
}
.snow:nth-child(35) {
  opacity: 0.9107;
  transform: translate(0.5283vw, -10px) scale(0.6826);
  animation: fall-35 18s -6s linear infinite;
}
@keyframes fall-35 {
  42.786% {
    transform: translate(2.1583vw, 42.786vh) scale(0.6826);
  }
  to {
    transform: translate(1.3433vw, 100vh) scale(0.6826);
  }
}
.snow:nth-child(36) {
  opacity: 0.4902;
  transform: translate(65.0606vw, -10px) scale(0.5842);
  animation: fall-36 19s -2s linear infinite;
}
@keyframes fall-36 {
  79.682% {
    transform: translate(65.9737vw, 79.682vh) scale(0.5842);
  }
  to {
    transform: translate(65.51715vw, 100vh) scale(0.5842);
  }
}
.snow:nth-child(37) {
  opacity: 0.2648;
  transform: translate(21.1269vw, -10px) scale(0.4146);
  animation: fall-37 24s -15s linear infinite;
}
@keyframes fall-37 {
  56.175% {
    transform: translate(25.6986vw, 56.175vh) scale(0.4146);
  }
  to {
    transform: translate(23.41275vw, 100vh) scale(0.4146);
  }
}
.snow:nth-child(38) {
  opacity: 0.5529;
  transform: translate(26.44vw, -10px) scale(0.1631);
  animation: fall-38 29s -18s linear infinite;
}
@keyframes fall-38 {
  54.657% {
    transform: translate(30.7483vw, 54.657vh) scale(0.1631);
  }
  to {
    transform: translate(28.59415vw, 100vh) scale(0.1631);
  }
}
.snow:nth-child(39) {
  opacity: 0.3286;
  transform: translate(24.3679vw, -10px) scale(0.8959);
  animation: fall-39 20s -18s linear infinite;
}
@keyframes fall-39 {
  49.538% {
    transform: translate(16.0231vw, 49.538vh) scale(0.8959);
  }
  to {
    transform: translate(20.1955vw, 100vh) scale(0.8959);
  }
}
.snow:nth-child(40) {
  opacity: 0.1753;
  transform: translate(72.9958vw, -10px) scale(0.4838);
  animation: fall-40 28s -30s linear infinite;
}
@keyframes fall-40 {
  56.975% {
    transform: translate(68.7839vw, 56.975vh) scale(0.4838);
  }
  to {
    transform: translate(70.88985vw, 100vh) scale(0.4838);
  }
}
.snow:nth-child(41) {
  opacity: 0.2719;
  transform: translate(62.3363vw, -10px) scale(0.4481);
  animation: fall-41 16s -29s linear infinite;
}
@keyframes fall-41 {
  77.893% {
    transform: translate(54.3394vw, 77.893vh) scale(0.4481);
  }
  to {
    transform: translate(58.33785vw, 100vh) scale(0.4481);
  }
}
.snow:nth-child(42) {
  opacity: 0.7989;
  transform: translate(12.3129vw, -10px) scale(0.5339);
  animation: fall-42 27s -9s linear infinite;
}
@keyframes fall-42 {
  45.255% {
    transform: translate(3.967vw, 45.255vh) scale(0.5339);
  }
  to {
    transform: translate(8.13995vw, 100vh) scale(0.5339);
  }
}
.snow:nth-child(43) {
  opacity: 0.9406;
  transform: translate(97.9218vw, -10px) scale(0.39);
  animation: fall-43 11s -20s linear infinite;
}
@keyframes fall-43 {
  41.063% {
    transform: translate(95.9058vw, 41.063vh) scale(0.39);
  }
  to {
    transform: translate(96.9138vw, 100vh) scale(0.39);
  }
}
.snow:nth-child(44) {
  opacity: 0.2586;
  transform: translate(31.7236vw, -10px) scale(0.047);
  animation: fall-44 22s -17s linear infinite;
}
@keyframes fall-44 {
  48.036% {
    transform: translate(33.2676vw, 48.036vh) scale(0.047);
  }
  to {
    transform: translate(32.4956vw, 100vh) scale(0.047);
  }
}
.snow:nth-child(45) {
  opacity: 0.4242;
  transform: translate(92.2262vw, -10px) scale(0.2038);
  animation: fall-45 23s -12s linear infinite;
}
@keyframes fall-45 {
  58.309% {
    transform: translate(94.1517vw, 58.309vh) scale(0.2038);
  }
  to {
    transform: translate(93.18895vw, 100vh) scale(0.2038);
  }
}
.snow:nth-child(46) {
  opacity: 0.9248;
  transform: translate(80.0666vw, -10px) scale(0.954);
  animation: fall-46 21s -19s linear infinite;
}
@keyframes fall-46 {
  35.331% {
    transform: translate(89.4187vw, 35.331vh) scale(0.954);
  }
  to {
    transform: translate(84.74265vw, 100vh) scale(0.954);
  }
}
.snow:nth-child(47) {
  opacity: 0.1335;
  transform: translate(8.9138vw, -10px) scale(0.6738);
  animation: fall-47 25s -26s linear infinite;
}
@keyframes fall-47 {
  35.818% {
    transform: translate(15.083vw, 35.818vh) scale(0.6738);
  }
  to {
    transform: translate(11.9984vw, 100vh) scale(0.6738);
  }
}
.snow:nth-child(48) {
  opacity: 0.5881;
  transform: translate(74.0564vw, -10px) scale(0.5079);
  animation: fall-48 25s -28s linear infinite;
}
@keyframes fall-48 {
  35.707% {
    transform: translate(76.5841vw, 35.707vh) scale(0.5079);
  }
  to {
    transform: translate(75.32025vw, 100vh) scale(0.5079);
  }
}
.snow:nth-child(49) {
  opacity: 0.7508;
  transform: translate(60.3493vw, -10px) scale(0.0732);
  animation: fall-49 21s -4s linear infinite;
}
@keyframes fall-49 {
  47.584% {
    transform: translate(55.7653vw, 47.584vh) scale(0.0732);
  }
  to {
    transform: translate(58.0573vw, 100vh) scale(0.0732);
  }
}
.snow:nth-child(50) {
  opacity: 0.5267;
  transform: translate(13.1535vw, -10px) scale(0.4051);
  animation: fall-50 11s -13s linear infinite;
}
@keyframes fall-50 {
  62.348% {
    transform: translate(18.4558vw, 62.348vh) scale(0.4051);
  }
  to {
    transform: translate(15.80465vw, 100vh) scale(0.4051);
  }
}
.snow:nth-child(51) {
  opacity: 0.7606;
  transform: translate(42.9826vw, -10px) scale(0.3557);
  animation: fall-51 23s -28s linear infinite;
}
@keyframes fall-51 {
  59.814% {
    transform: translate(39.844vw, 59.814vh) scale(0.3557);
  }
  to {
    transform: translate(41.4133vw, 100vh) scale(0.3557);
  }
}
.snow:nth-child(52) {
  opacity: 0.9664;
  transform: translate(36.2175vw, -10px) scale(0.0766);
  animation: fall-52 28s -9s linear infinite;
}
@keyframes fall-52 {
  46.165% {
    transform: translate(38.6273vw, 46.165vh) scale(0.0766);
  }
  to {
    transform: translate(37.4224vw, 100vh) scale(0.0766);
  }
}
.snow:nth-child(53) {
  opacity: 0.5084;
  transform: translate(38.3516vw, -10px) scale(0.0028);
  animation: fall-53 17s -5s linear infinite;
}
@keyframes fall-53 {
  77.887% {
    transform: translate(28.4049vw, 77.887vh) scale(0.0028);
  }
  to {
    transform: translate(33.37825vw, 100vh) scale(0.0028);
  }
}
.snow:nth-child(54) {
  opacity: 0.4271;
  transform: translate(73.5849vw, -10px) scale(0.4086);
  animation: fall-54 14s -2s linear infinite;
}
@keyframes fall-54 {
  46.289% {
    transform: translate(64.643vw, 46.289vh) scale(0.4086);
  }
  to {
    transform: translate(69.11395vw, 100vh) scale(0.4086);
  }
}
.snow:nth-child(55) {
  opacity: 0.7494;
  transform: translate(42.5567vw, -10px) scale(0.3228);
  animation: fall-55 17s -30s linear infinite;
}
@keyframes fall-55 {
  66.245% {
    transform: translate(32.9915vw, 66.245vh) scale(0.3228);
  }
  to {
    transform: translate(37.7741vw, 100vh) scale(0.3228);
  }
}
.snow:nth-child(56) {
  opacity: 0.9512;
  transform: translate(72.4856vw, -10px) scale(0.0329);
  animation: fall-56 10s -23s linear infinite;
}
@keyframes fall-56 {
  39.305% {
    transform: translate(78.3358vw, 39.305vh) scale(0.0329);
  }
  to {
    transform: translate(75.4107vw, 100vh) scale(0.0329);
  }
}
.snow:nth-child(57) {
  opacity: 0.1294;
  transform: translate(51.9577vw, -10px) scale(0.2417);
  animation: fall-57 28s -12s linear infinite;
}
@keyframes fall-57 {
  49.34% {
    transform: translate(44.3884vw, 49.34vh) scale(0.2417);
  }
  to {
    transform: translate(48.17305vw, 100vh) scale(0.2417);
  }
}
.snow:nth-child(58) {
  opacity: 0.8285;
  transform: translate(13.6958vw, -10px) scale(0.862);
  animation: fall-58 26s -14s linear infinite;
}
@keyframes fall-58 {
  66.982% {
    transform: translate(5.6085vw, 66.982vh) scale(0.862);
  }
  to {
    transform: translate(9.65215vw, 100vh) scale(0.862);
  }
}
.snow:nth-child(59) {
  opacity: 0.5034;
  transform: translate(78.508vw, -10px) scale(0.7515);
  animation: fall-59 15s -11s linear infinite;
}
@keyframes fall-59 {
  53.459% {
    transform: translate(69.6642vw, 53.459vh) scale(0.7515);
  }
  to {
    transform: translate(74.0861vw, 100vh) scale(0.7515);
  }
}
.snow:nth-child(60) {
  opacity: 0.8937;
  transform: translate(83.973vw, -10px) scale(0.551);
  animation: fall-60 20s -1s linear infinite;
}
@keyframes fall-60 {
  58.899% {
    transform: translate(88.1673vw, 58.899vh) scale(0.551);
  }
  to {
    transform: translate(86.07015vw, 100vh) scale(0.551);
  }
}
.snow:nth-child(61) {
  opacity: 0.0494;
  transform: translate(91.4305vw, -10px) scale(0.1139);
  animation: fall-61 19s -26s linear infinite;
}
@keyframes fall-61 {
  43.634% {
    transform: translate(89.8872vw, 43.634vh) scale(0.1139);
  }
  to {
    transform: translate(90.65885vw, 100vh) scale(0.1139);
  }
}
.snow:nth-child(62) {
  opacity: 0.3352;
  transform: translate(14.0243vw, -10px) scale(0.6103);
  animation: fall-62 26s -25s linear infinite;
}
@keyframes fall-62 {
  63.975% {
    transform: translate(13.2871vw, 63.975vh) scale(0.6103);
  }
  to {
    transform: translate(13.6557vw, 100vh) scale(0.6103);
  }
}
.snow:nth-child(63) {
  opacity: 0.304;
  transform: translate(40.7365vw, -10px) scale(0.9245);
  animation: fall-63 29s -30s linear infinite;
}
@keyframes fall-63 {
  35.525% {
    transform: translate(33.2029vw, 35.525vh) scale(0.9245);
  }
  to {
    transform: translate(36.9697vw, 100vh) scale(0.9245);
  }
}
.snow:nth-child(64) {
  opacity: 0.1341;
  transform: translate(87.8635vw, -10px) scale(0.9588);
  animation: fall-64 29s -3s linear infinite;
}
@keyframes fall-64 {
  64.662% {
    transform: translate(91.3147vw, 64.662vh) scale(0.9588);
  }
  to {
    transform: translate(89.5891vw, 100vh) scale(0.9588);
  }
}
.snow:nth-child(65) {
  opacity: 0.5395;
  transform: translate(96.7817vw, -10px) scale(0.8456);
  animation: fall-65 17s -18s linear infinite;
}
@keyframes fall-65 {
  40.222% {
    transform: translate(93.7879vw, 40.222vh) scale(0.8456);
  }
  to {
    transform: translate(95.2848vw, 100vh) scale(0.8456);
  }
}
.snow:nth-child(66) {
  opacity: 0.8826;
  transform: translate(79.453vw, -10px) scale(0.515);
  animation: fall-66 11s -30s linear infinite;
}
@keyframes fall-66 {
  66.882% {
    transform: translate(83.2401vw, 66.882vh) scale(0.515);
  }
  to {
    transform: translate(81.34655vw, 100vh) scale(0.515);
  }
}
.snow:nth-child(67) {
  opacity: 0.9491;
  transform: translate(17.9608vw, -10px) scale(0.034);
  animation: fall-67 20s -24s linear infinite;
}
@keyframes fall-67 {
  36.304% {
    transform: translate(22.9833vw, 36.304vh) scale(0.034);
  }
  to {
    transform: translate(20.47205vw, 100vh) scale(0.034);
  }
}
.snow:nth-child(68) {
  opacity: 0.131;
  transform: translate(59.0144vw, -10px) scale(0.5752);
  animation: fall-68 19s -17s linear infinite;
}
@keyframes fall-68 {
  48.832% {
    transform: translate(65.9493vw, 48.832vh) scale(0.5752);
  }
  to {
    transform: translate(62.48185vw, 100vh) scale(0.5752);
  }
}
.snow:nth-child(69) {
  opacity: 0.2983;
  transform: translate(40.2282vw, -10px) scale(0.3815);
  animation: fall-69 17s -11s linear infinite;
}
@keyframes fall-69 {
  73.801% {
    transform: translate(39.379vw, 73.801vh) scale(0.3815);
  }
  to {
    transform: translate(39.8036vw, 100vh) scale(0.3815);
  }
}
.snow:nth-child(70) {
  opacity: 0.9636;
  transform: translate(32.9623vw, -10px) scale(0.0021);
  animation: fall-70 27s -6s linear infinite;
}
@keyframes fall-70 {
  63.769% {
    transform: translate(37.7655vw, 63.769vh) scale(0.0021);
  }
  to {
    transform: translate(35.3639vw, 100vh) scale(0.0021);
  }
}
.snow:nth-child(71) {
  opacity: 0.2503;
  transform: translate(36.5058vw, -10px) scale(0.9624);
  animation: fall-71 30s -3s linear infinite;
}
@keyframes fall-71 {
  66.234% {
    transform: translate(45.581vw, 66.234vh) scale(0.9624);
  }
  to {
    transform: translate(41.0434vw, 100vh) scale(0.9624);
  }
}
.snow:nth-child(72) {
  opacity: 0.0697;
  transform: translate(80.0733vw, -10px) scale(0.4522);
  animation: fall-72 14s -25s linear infinite;
}
@keyframes fall-72 {
  70.747% {
    transform: translate(87.9578vw, 70.747vh) scale(0.4522);
  }
  to {
    transform: translate(84.01555vw, 100vh) scale(0.4522);
  }
}
.snow:nth-child(73) {
  opacity: 0.3912;
  transform: translate(17.0519vw, -10px) scale(0.7398);
  animation: fall-73 29s -5s linear infinite;
}
@keyframes fall-73 {
  41.643% {
    transform: translate(16.4343vw, 41.643vh) scale(0.7398);
  }
  to {
    transform: translate(16.7431vw, 100vh) scale(0.7398);
  }
}
.snow:nth-child(74) {
  opacity: 0.1419;
  transform: translate(6.1533vw, -10px) scale(0.6559);
  animation: fall-74 18s -28s linear infinite;
}
@keyframes fall-74 {
  52.696% {
    transform: translate(0.9924vw, 52.696vh) scale(0.6559);
  }
  to {
    transform: translate(3.57285vw, 100vh) scale(0.6559);
  }
}
.snow:nth-child(75) {
  opacity: 0.6888;
  transform: translate(94.8356vw, -10px) scale(0.7792);
  animation: fall-75 10s -10s linear infinite;
}
@keyframes fall-75 {
  65.02% {
    transform: translate(104.4168vw, 65.02vh) scale(0.7792);
  }
  to {
    transform: translate(99.6262vw, 100vh) scale(0.7792);
  }
}
.snow:nth-child(76) {
  opacity: 0.1536;
  transform: translate(51.2436vw, -10px) scale(0.3872);
  animation: fall-76 23s -29s linear infinite;
}
@keyframes fall-76 {
  30.741% {
    transform: translate(60.2895vw, 30.741vh) scale(0.3872);
  }
  to {
    transform: translate(55.76655vw, 100vh) scale(0.3872);
  }
}
.snow:nth-child(77) {
  opacity: 0.6763;
  transform: translate(42.1806vw, -10px) scale(0.8973);
  animation: fall-77 12s -27s linear infinite;
}
@keyframes fall-77 {
  68.978% {
    transform: translate(35.5133vw, 68.978vh) scale(0.8973);
  }
  to {
    transform: translate(38.84695vw, 100vh) scale(0.8973);
  }
}
.snow:nth-child(78) {
  opacity: 0.9939;
  transform: translate(50.3725vw, -10px) scale(0.7118);
  animation: fall-78 10s -20s linear infinite;
}
@keyframes fall-78 {
  43.677% {
    transform: translate(47.6422vw, 43.677vh) scale(0.7118);
  }
  to {
    transform: translate(49.00735vw, 100vh) scale(0.7118);
  }
}
.snow:nth-child(79) {
  opacity: 0.1046;
  transform: translate(42.2392vw, -10px) scale(0.9593);
  animation: fall-79 16s -13s linear infinite;
}
@keyframes fall-79 {
  48.073% {
    transform: translate(38.8384vw, 48.073vh) scale(0.9593);
  }
  to {
    transform: translate(40.5388vw, 100vh) scale(0.9593);
  }
}
.snow:nth-child(80) {
  opacity: 0.9;
  transform: translate(3.1409vw, -10px) scale(0.8436);
  animation: fall-80 27s -1s linear infinite;
}
@keyframes fall-80 {
  68.166% {
    transform: translate(11.9077vw, 68.166vh) scale(0.8436);
  }
  to {
    transform: translate(7.5243vw, 100vh) scale(0.8436);
  }
}
.snow:nth-child(81) {
  opacity: 0.6922;
  transform: translate(95.6699vw, -10px) scale(0.3495);
  animation: fall-81 17s -28s linear infinite;
}
@keyframes fall-81 {
  38.847% {
    transform: translate(98.0372vw, 38.847vh) scale(0.3495);
  }
  to {
    transform: translate(96.85355vw, 100vh) scale(0.3495);
  }
}
.snow:nth-child(82) {
  opacity: 0.9482;
  transform: translate(15.2759vw, -10px) scale(0.6771);
  animation: fall-82 12s -25s linear infinite;
}
@keyframes fall-82 {
  65.37% {
    transform: translate(20.6052vw, 65.37vh) scale(0.6771);
  }
  to {
    transform: translate(17.94055vw, 100vh) scale(0.6771);
  }
}
.snow:nth-child(83) {
  opacity: 0.0415;
  transform: translate(61.7365vw, -10px) scale(0.8256);
  animation: fall-83 11s -9s linear infinite;
}
@keyframes fall-83 {
  49.864% {
    transform: translate(61.6783vw, 49.864vh) scale(0.8256);
  }
  to {
    transform: translate(61.7074vw, 100vh) scale(0.8256);
  }
}
.snow:nth-child(84) {
  opacity: 0.2003;
  transform: translate(19.6236vw, -10px) scale(0.458);
  animation: fall-84 24s -27s linear infinite;
}
@keyframes fall-84 {
  68.42% {
    transform: translate(27.0899vw, 68.42vh) scale(0.458);
  }
  to {
    transform: translate(23.35675vw, 100vh) scale(0.458);
  }
}
.snow:nth-child(85) {
  opacity: 0.1316;
  transform: translate(34.2589vw, -10px) scale(0.6914);
  animation: fall-85 30s -3s linear infinite;
}
@keyframes fall-85 {
  70.638% {
    transform: translate(37.4806vw, 70.638vh) scale(0.6914);
  }
  to {
    transform: translate(35.86975vw, 100vh) scale(0.6914);
  }
}
.snow:nth-child(86) {
  opacity: 0.7663;
  transform: translate(56.4765vw, -10px) scale(0.121);
  animation: fall-86 17s -6s linear infinite;
}
@keyframes fall-86 {
  49.913% {
    transform: translate(60.7503vw, 49.913vh) scale(0.121);
  }
  to {
    transform: translate(58.6134vw, 100vh) scale(0.121);
  }
}
.snow:nth-child(87) {
  opacity: 0.6457;
  transform: translate(78.6226vw, -10px) scale(0.0593);
  animation: fall-87 24s -25s linear infinite;
}
@keyframes fall-87 {
  57.375% {
    transform: translate(82.5246vw, 57.375vh) scale(0.0593);
  }
  to {
    transform: translate(80.5736vw, 100vh) scale(0.0593);
  }
}
.snow:nth-child(88) {
  opacity: 0.1172;
  transform: translate(20.777vw, -10px) scale(0.6636);
  animation: fall-88 15s -16s linear infinite;
}
@keyframes fall-88 {
  78.135% {
    transform: translate(11.0638vw, 78.135vh) scale(0.6636);
  }
  to {
    transform: translate(15.9204vw, 100vh) scale(0.6636);
  }
}
.snow:nth-child(89) {
  opacity: 0.7372;
  transform: translate(69.1915vw, -10px) scale(0.6764);
  animation: fall-89 16s -7s linear infinite;
}
@keyframes fall-89 {
  63.175% {
    transform: translate(76.2362vw, 63.175vh) scale(0.6764);
  }
  to {
    transform: translate(72.71385vw, 100vh) scale(0.6764);
  }
}
.snow:nth-child(90) {
  opacity: 0.4772;
  transform: translate(90.8846vw, -10px) scale(0.465);
  animation: fall-90 19s -25s linear infinite;
}
@keyframes fall-90 {
  51.659% {
    transform: translate(96.5956vw, 51.659vh) scale(0.465);
  }
  to {
    transform: translate(93.7401vw, 100vh) scale(0.465);
  }
}
.snow:nth-child(91) {
  opacity: 0.9988;
  transform: translate(29.5627vw, -10px) scale(0.4176);
  animation: fall-91 21s -4s linear infinite;
}
@keyframes fall-91 {
  52.61% {
    transform: translate(21.4699vw, 52.61vh) scale(0.4176);
  }
  to {
    transform: translate(25.5163vw, 100vh) scale(0.4176);
  }
}
.snow:nth-child(92) {
  opacity: 0.4008;
  transform: translate(80.8867vw, -10px) scale(0.6884);
  animation: fall-92 30s -8s linear infinite;
}
@keyframes fall-92 {
  32.066% {
    transform: translate(72.5247vw, 32.066vh) scale(0.6884);
  }
  to {
    transform: translate(76.7057vw, 100vh) scale(0.6884);
  }
}
.snow:nth-child(93) {
  opacity: 0.0713;
  transform: translate(20.6317vw, -10px) scale(0.1743);
  animation: fall-93 23s -29s linear infinite;
}
@keyframes fall-93 {
  38.881% {
    transform: translate(29.6535vw, 38.881vh) scale(0.1743);
  }
  to {
    transform: translate(25.1426vw, 100vh) scale(0.1743);
  }
}
.snow:nth-child(94) {
  opacity: 0.3643;
  transform: translate(24.0109vw, -10px) scale(0.5476);
  animation: fall-94 23s -9s linear infinite;
}
@keyframes fall-94 {
  69.103% {
    transform: translate(32.979vw, 69.103vh) scale(0.5476);
  }
  to {
    transform: translate(28.49495vw, 100vh) scale(0.5476);
  }
}
.snow:nth-child(95) {
  opacity: 0.0713;
  transform: translate(81.0465vw, -10px) scale(0.6428);
  animation: fall-95 23s -2s linear infinite;
}
@keyframes fall-95 {
  36.757% {
    transform: translate(73.9036vw, 36.757vh) scale(0.6428);
  }
  to {
    transform: translate(77.47505vw, 100vh) scale(0.6428);
  }
}
.snow:nth-child(96) {
  opacity: 0.8293;
  transform: translate(69.2758vw, -10px) scale(0.5592);
  animation: fall-96 22s -12s linear infinite;
}
@keyframes fall-96 {
  58.767% {
    transform: translate(72.4926vw, 58.767vh) scale(0.5592);
  }
  to {
    transform: translate(70.8842vw, 100vh) scale(0.5592);
  }
}
.snow:nth-child(97) {
  opacity: 0.833;
  transform: translate(35.8811vw, -10px) scale(0.2406);
  animation: fall-97 19s -15s linear infinite;
}
@keyframes fall-97 {
  61.513% {
    transform: translate(42.1741vw, 61.513vh) scale(0.2406);
  }
  to {
    transform: translate(39.0276vw, 100vh) scale(0.2406);
  }
}
.snow:nth-child(98) {
  opacity: 0.9575;
  transform: translate(35.8881vw, -10px) scale(0.3979);
  animation: fall-98 10s -29s linear infinite;
}
@keyframes fall-98 {
  45.445% {
    transform: translate(38.7063vw, 45.445vh) scale(0.3979);
  }
  to {
    transform: translate(37.2972vw, 100vh) scale(0.3979);
  }
}
.snow:nth-child(99) {
  opacity: 0.1375;
  transform: translate(97.5428vw, -10px) scale(0.4807);
  animation: fall-99 20s -18s linear infinite;
}
@keyframes fall-99 {
  40.687% {
    transform: translate(101.6464vw, 40.687vh) scale(0.4807);
  }
  to {
    transform: translate(99.5946vw, 100vh) scale(0.4807);
  }
}
.snow:nth-child(100) {
  opacity: 0.0542;
  transform: translate(53.4359vw, -10px) scale(0.0131);
  animation: fall-100 24s -16s linear infinite;
}
@keyframes fall-100 {
  61.786% {
    transform: translate(60.8799vw, 61.786vh) scale(0.0131);
  }
  to {
    transform: translate(57.1579vw, 100vh) scale(0.0131);
  }
}
.snow:nth-child(101) {
  opacity: 0.4012;
  transform: translate(20.3581vw, -10px) scale(0.2773);
  animation: fall-101 20s -29s linear infinite;
}
@keyframes fall-101 {
  68.94% {
    transform: translate(23.7221vw, 68.94vh) scale(0.2773);
  }
  to {
    transform: translate(22.0401vw, 100vh) scale(0.2773);
  }
}
.snow:nth-child(102) {
  opacity: 0.6348;
  transform: translate(57.0456vw, -10px) scale(0.6557);
  animation: fall-102 26s -13s linear infinite;
}
@keyframes fall-102 {
  43.546% {
    transform: translate(64.8328vw, 43.546vh) scale(0.6557);
  }
  to {
    transform: translate(60.9392vw, 100vh) scale(0.6557);
  }
}
.snow:nth-child(103) {
  opacity: 0.2378;
  transform: translate(58.6828vw, -10px) scale(0.7902);
  animation: fall-103 15s -9s linear infinite;
}
@keyframes fall-103 {
  44.363% {
    transform: translate(50.4082vw, 44.363vh) scale(0.7902);
  }
  to {
    transform: translate(54.5455vw, 100vh) scale(0.7902);
  }
}
.snow:nth-child(104) {
  opacity: 0.1547;
  transform: translate(3.46vw, -10px) scale(0.1704);
  animation: fall-104 22s -16s linear infinite;
}
@keyframes fall-104 {
  64.301% {
    transform: translate(2.2672vw, 64.301vh) scale(0.1704);
  }
  to {
    transform: translate(2.8636vw, 100vh) scale(0.1704);
  }
}
.snow:nth-child(105) {
  opacity: 0.086;
  transform: translate(68.8339vw, -10px) scale(0.539);
  animation: fall-105 19s -24s linear infinite;
}
@keyframes fall-105 {
  49.08% {
    transform: translate(71.5882vw, 49.08vh) scale(0.539);
  }
  to {
    transform: translate(70.21105vw, 100vh) scale(0.539);
  }
}
.snow:nth-child(106) {
  opacity: 0.4135;
  transform: translate(18.614vw, -10px) scale(0.8119);
  animation: fall-106 18s -21s linear infinite;
}
@keyframes fall-106 {
  34.064% {
    transform: translate(22.8439vw, 34.064vh) scale(0.8119);
  }
  to {
    transform: translate(20.72895vw, 100vh) scale(0.8119);
  }
}
.snow:nth-child(107) {
  opacity: 0.2517;
  transform: translate(11.3645vw, -10px) scale(0.1386);
  animation: fall-107 15s -2s linear infinite;
}
@keyframes fall-107 {
  70.054% {
    transform: translate(14.3352vw, 70.054vh) scale(0.1386);
  }
  to {
    transform: translate(12.84985vw, 100vh) scale(0.1386);
  }
}
.snow:nth-child(108) {
  opacity: 0.2482;
  transform: translate(43.7243vw, -10px) scale(0.9259);
  animation: fall-108 24s -21s linear infinite;
}
@keyframes fall-108 {
  77.406% {
    transform: translate(46.4111vw, 77.406vh) scale(0.9259);
  }
  to {
    transform: translate(45.0677vw, 100vh) scale(0.9259);
  }
}
.snow:nth-child(109) {
  opacity: 0.9971;
  transform: translate(39.3307vw, -10px) scale(0.4437);
  animation: fall-109 15s -25s linear infinite;
}
@keyframes fall-109 {
  76.036% {
    transform: translate(31.7808vw, 76.036vh) scale(0.4437);
  }
  to {
    transform: translate(35.55575vw, 100vh) scale(0.4437);
  }
}
.snow:nth-child(110) {
  opacity: 0.4352;
  transform: translate(6.2097vw, -10px) scale(0.5695);
  animation: fall-110 21s -24s linear infinite;
}
@keyframes fall-110 {
  37.776% {
    transform: translate(2.8425vw, 37.776vh) scale(0.5695);
  }
  to {
    transform: translate(4.5261vw, 100vh) scale(0.5695);
  }
}
.snow:nth-child(111) {
  opacity: 0.5401;
  transform: translate(69.0925vw, -10px) scale(0.9429);
  animation: fall-111 17s -19s linear infinite;
}
@keyframes fall-111 {
  36.809% {
    transform: translate(65.1428vw, 36.809vh) scale(0.9429);
  }
  to {
    transform: translate(67.11765vw, 100vh) scale(0.9429);
  }
}
.snow:nth-child(112) {
  opacity: 0.5815;
  transform: translate(96.7968vw, -10px) scale(0.9608);
  animation: fall-112 25s -1s linear infinite;
}
@keyframes fall-112 {
  62.832% {
    transform: translate(91.1596vw, 62.832vh) scale(0.9608);
  }
  to {
    transform: translate(93.9782vw, 100vh) scale(0.9608);
  }
}
.snow:nth-child(113) {
  opacity: 0.5271;
  transform: translate(60.7769vw, -10px) scale(0.0524);
  animation: fall-113 13s -30s linear infinite;
}
@keyframes fall-113 {
  66.61% {
    transform: translate(67.5362vw, 66.61vh) scale(0.0524);
  }
  to {
    transform: translate(64.15655vw, 100vh) scale(0.0524);
  }
}
.snow:nth-child(114) {
  opacity: 0.8672;
  transform: translate(3.7168vw, -10px) scale(0.4068);
  animation: fall-114 15s -16s linear infinite;
}
@keyframes fall-114 {
  47.598% {
    transform: translate(11.15vw, 47.598vh) scale(0.4068);
  }
  to {
    transform: translate(7.4334vw, 100vh) scale(0.4068);
  }
}
.snow:nth-child(115) {
  opacity: 0.9426;
  transform: translate(94.4169vw, -10px) scale(0.2617);
  animation: fall-115 28s -17s linear infinite;
}
@keyframes fall-115 {
  77.349% {
    transform: translate(103.3753vw, 77.349vh) scale(0.2617);
  }
  to {
    transform: translate(98.8961vw, 100vh) scale(0.2617);
  }
}
.snow:nth-child(116) {
  opacity: 0.886;
  transform: translate(57.6986vw, -10px) scale(0.071);
  animation: fall-116 28s -18s linear infinite;
}
@keyframes fall-116 {
  78.33% {
    transform: translate(58.9168vw, 78.33vh) scale(0.071);
  }
  to {
    transform: translate(58.3077vw, 100vh) scale(0.071);
  }
}
.snow:nth-child(117) {
  opacity: 0.186;
  transform: translate(64.165vw, -10px) scale(0.6939);
  animation: fall-117 18s -1s linear infinite;
}
@keyframes fall-117 {
  39.314% {
    transform: translate(69.2782vw, 39.314vh) scale(0.6939);
  }
  to {
    transform: translate(66.7216vw, 100vh) scale(0.6939);
  }
}
.snow:nth-child(118) {
  opacity: 0.2135;
  transform: translate(73.1439vw, -10px) scale(0.0775);
  animation: fall-118 27s -23s linear infinite;
}
@keyframes fall-118 {
  57.905% {
    transform: translate(67.9169vw, 57.905vh) scale(0.0775);
  }
  to {
    transform: translate(70.5304vw, 100vh) scale(0.0775);
  }
}
.snow:nth-child(119) {
  opacity: 0.2711;
  transform: translate(50.7578vw, -10px) scale(0.5502);
  animation: fall-119 19s -15s linear infinite;
}
@keyframes fall-119 {
  43.781% {
    transform: translate(51.9845vw, 43.781vh) scale(0.5502);
  }
  to {
    transform: translate(51.37115vw, 100vh) scale(0.5502);
  }
}
.snow:nth-child(120) {
  opacity: 0.9548;
  transform: translate(65.3727vw, -10px) scale(0.2896);
  animation: fall-120 21s -11s linear infinite;
}
@keyframes fall-120 {
  38.385% {
    transform: translate(61.8289vw, 38.385vh) scale(0.2896);
  }
  to {
    transform: translate(63.6008vw, 100vh) scale(0.2896);
  }
}
.snow:nth-child(121) {
  opacity: 0.0514;
  transform: translate(61.8699vw, -10px) scale(0.4629);
  animation: fall-121 11s -17s linear infinite;
}
@keyframes fall-121 {
  44.426% {
    transform: translate(70.0057vw, 44.426vh) scale(0.4629);
  }
  to {
    transform: translate(65.9378vw, 100vh) scale(0.4629);
  }
}
.snow:nth-child(122) {
  opacity: 0.8828;
  transform: translate(77.5323vw, -10px) scale(0.3557);
  animation: fall-122 30s -26s linear infinite;
}
@keyframes fall-122 {
  51.61% {
    transform: translate(73.2265vw, 51.61vh) scale(0.3557);
  }
  to {
    transform: translate(75.3794vw, 100vh) scale(0.3557);
  }
}
.snow:nth-child(123) {
  opacity: 0.2366;
  transform: translate(79.4526vw, -10px) scale(0.5534);
  animation: fall-123 17s -29s linear infinite;
}
@keyframes fall-123 {
  63.915% {
    transform: translate(72.7197vw, 63.915vh) scale(0.5534);
  }
  to {
    transform: translate(76.08615vw, 100vh) scale(0.5534);
  }
}
.snow:nth-child(124) {
  opacity: 0.892;
  transform: translate(83.2979vw, -10px) scale(0.5166);
  animation: fall-124 17s -2s linear infinite;
}
@keyframes fall-124 {
  62.415% {
    transform: translate(77.657vw, 62.415vh) scale(0.5166);
  }
  to {
    transform: translate(80.47745vw, 100vh) scale(0.5166);
  }
}
.snow:nth-child(125) {
  opacity: 0.7944;
  transform: translate(71.572vw, -10px) scale(0.7357);
  animation: fall-125 16s -25s linear infinite;
}
@keyframes fall-125 {
  53.736% {
    transform: translate(74.5316vw, 53.736vh) scale(0.7357);
  }
  to {
    transform: translate(73.0518vw, 100vh) scale(0.7357);
  }
}
.snow:nth-child(126) {
  opacity: 0.0125;
  transform: translate(81.2951vw, -10px) scale(0.7408);
  animation: fall-126 17s -7s linear infinite;
}
@keyframes fall-126 {
  59.216% {
    transform: translate(85.2746vw, 59.216vh) scale(0.7408);
  }
  to {
    transform: translate(83.28485vw, 100vh) scale(0.7408);
  }
}
.snow:nth-child(127) {
  opacity: 0.8096;
  transform: translate(49.7534vw, -10px) scale(0.7583);
  animation: fall-127 29s -4s linear infinite;
}
@keyframes fall-127 {
  64.915% {
    transform: translate(53.2343vw, 64.915vh) scale(0.7583);
  }
  to {
    transform: translate(51.49385vw, 100vh) scale(0.7583);
  }
}
.snow:nth-child(128) {
  opacity: 0.3995;
  transform: translate(83.2391vw, -10px) scale(0.5616);
  animation: fall-128 19s -23s linear infinite;
}
@keyframes fall-128 {
  51.828% {
    transform: translate(76.7356vw, 51.828vh) scale(0.5616);
  }
  to {
    transform: translate(79.98735vw, 100vh) scale(0.5616);
  }
}
.snow:nth-child(129) {
  opacity: 0.278;
  transform: translate(35.3368vw, -10px) scale(0.0151);
  animation: fall-129 25s -9s linear infinite;
}
@keyframes fall-129 {
  60.841% {
    transform: translate(34.3878vw, 60.841vh) scale(0.0151);
  }
  to {
    transform: translate(34.8623vw, 100vh) scale(0.0151);
  }
}
.snow:nth-child(130) {
  opacity: 0.8282;
  transform: translate(62.2612vw, -10px) scale(0.9691);
  animation: fall-130 16s -13s linear infinite;
}
@keyframes fall-130 {
  73.63% {
    transform: translate(60.4865vw, 73.63vh) scale(0.9691);
  }
  to {
    transform: translate(61.37385vw, 100vh) scale(0.9691);
  }
}
.snow:nth-child(131) {
  opacity: 0.335;
  transform: translate(19.4913vw, -10px) scale(0.5931);
  animation: fall-131 10s -12s linear infinite;
}
@keyframes fall-131 {
  69.572% {
    transform: translate(10.571vw, 69.572vh) scale(0.5931);
  }
  to {
    transform: translate(15.03115vw, 100vh) scale(0.5931);
  }
}
.snow:nth-child(132) {
  opacity: 0.2102;
  transform: translate(88.5537vw, -10px) scale(0.8998);
  animation: fall-132 29s -20s linear infinite;
}
@keyframes fall-132 {
  69.292% {
    transform: translate(87.9756vw, 69.292vh) scale(0.8998);
  }
  to {
    transform: translate(88.26465vw, 100vh) scale(0.8998);
  }
}
.snow:nth-child(133) {
  opacity: 0.3757;
  transform: translate(66.707vw, -10px) scale(0.5452);
  animation: fall-133 15s -30s linear infinite;
}
@keyframes fall-133 {
  65.399% {
    transform: translate(73.45vw, 65.399vh) scale(0.5452);
  }
  to {
    transform: translate(70.0785vw, 100vh) scale(0.5452);
  }
}
.snow:nth-child(134) {
  opacity: 0.4442;
  transform: translate(80.4485vw, -10px) scale(0.0484);
  animation: fall-134 24s -3s linear infinite;
}
@keyframes fall-134 {
  41.865% {
    transform: translate(89.0068vw, 41.865vh) scale(0.0484);
  }
  to {
    transform: translate(84.72765vw, 100vh) scale(0.0484);
  }
}
.snow:nth-child(135) {
  opacity: 0.1175;
  transform: translate(30.3988vw, -10px) scale(0.0629);
  animation: fall-135 29s -10s linear infinite;
}
@keyframes fall-135 {
  77.617% {
    transform: translate(21.5054vw, 77.617vh) scale(0.0629);
  }
  to {
    transform: translate(25.9521vw, 100vh) scale(0.0629);
  }
}
.snow:nth-child(136) {
  opacity: 0.6699;
  transform: translate(70.657vw, -10px) scale(0.88);
  animation: fall-136 19s -23s linear infinite;
}
@keyframes fall-136 {
  53.95% {
    transform: translate(64.8602vw, 53.95vh) scale(0.88);
  }
  to {
    transform: translate(67.7586vw, 100vh) scale(0.88);
  }
}
.snow:nth-child(137) {
  opacity: 0.6028;
  transform: translate(64.3665vw, -10px) scale(0.5741);
  animation: fall-137 24s -7s linear infinite;
}
@keyframes fall-137 {
  56.627% {
    transform: translate(59.1652vw, 56.627vh) scale(0.5741);
  }
  to {
    transform: translate(61.76585vw, 100vh) scale(0.5741);
  }
}
.snow:nth-child(138) {
  opacity: 0.0966;
  transform: translate(88.5013vw, -10px) scale(0.5571);
  animation: fall-138 18s -4s linear infinite;
}
@keyframes fall-138 {
  53.154% {
    transform: translate(87.5905vw, 53.154vh) scale(0.5571);
  }
  to {
    transform: translate(88.0459vw, 100vh) scale(0.5571);
  }
}
.snow:nth-child(139) {
  opacity: 0.1469;
  transform: translate(98.4796vw, -10px) scale(0.5429);
  animation: fall-139 16s -20s linear infinite;
}
@keyframes fall-139 {
  73.007% {
    transform: translate(107.0197vw, 73.007vh) scale(0.5429);
  }
  to {
    transform: translate(102.74965vw, 100vh) scale(0.5429);
  }
}
.snow:nth-child(140) {
  opacity: 0.0958;
  transform: translate(4.362vw, -10px) scale(0.7376);
  animation: fall-140 28s -18s linear infinite;
}
@keyframes fall-140 {
  36.522% {
    transform: translate(8.1677vw, 36.522vh) scale(0.7376);
  }
  to {
    transform: translate(6.26485vw, 100vh) scale(0.7376);
  }
}
.snow:nth-child(141) {
  opacity: 0.2234;
  transform: translate(65.1028vw, -10px) scale(0.9583);
  animation: fall-141 11s -16s linear infinite;
}
@keyframes fall-141 {
  50.219% {
    transform: translate(58.6106vw, 50.219vh) scale(0.9583);
  }
  to {
    transform: translate(61.8567vw, 100vh) scale(0.9583);
  }
}
.snow:nth-child(142) {
  opacity: 0.9619;
  transform: translate(30.1064vw, -10px) scale(0.6536);
  animation: fall-142 17s -15s linear infinite;
}
@keyframes fall-142 {
  51.846% {
    transform: translate(34.9756vw, 51.846vh) scale(0.6536);
  }
  to {
    transform: translate(32.541vw, 100vh) scale(0.6536);
  }
}
.snow:nth-child(143) {
  opacity: 0.6298;
  transform: translate(29.5802vw, -10px) scale(0.1945);
  animation: fall-143 29s -23s linear infinite;
}
@keyframes fall-143 {
  44.865% {
    transform: translate(39.3761vw, 44.865vh) scale(0.1945);
  }
  to {
    transform: translate(34.47815vw, 100vh) scale(0.1945);
  }
}
.snow:nth-child(144) {
  opacity: 0.2156;
  transform: translate(95.8317vw, -10px) scale(0.3894);
  animation: fall-144 26s -9s linear infinite;
}
@keyframes fall-144 {
  62.876% {
    transform: translate(100.4729vw, 62.876vh) scale(0.3894);
  }
  to {
    transform: translate(98.1523vw, 100vh) scale(0.3894);
  }
}
.snow:nth-child(145) {
  opacity: 0.6229;
  transform: translate(38.6274vw, -10px) scale(0.6513);
  animation: fall-145 18s -16s linear infinite;
}
@keyframes fall-145 {
  77.001% {
    transform: translate(37.002vw, 77.001vh) scale(0.6513);
  }
  to {
    transform: translate(37.8147vw, 100vh) scale(0.6513);
  }
}
.snow:nth-child(146) {
  opacity: 0.3703;
  transform: translate(61.4584vw, -10px) scale(0.9941);
  animation: fall-146 27s -25s linear infinite;
}
@keyframes fall-146 {
  60.518% {
    transform: translate(60.2823vw, 60.518vh) scale(0.9941);
  }
  to {
    transform: translate(60.87035vw, 100vh) scale(0.9941);
  }
}
.snow:nth-child(147) {
  opacity: 0.7791;
  transform: translate(74.616vw, -10px) scale(0.055);
  animation: fall-147 12s -10s linear infinite;
}
@keyframes fall-147 {
  30.243% {
    transform: translate(76.0349vw, 30.243vh) scale(0.055);
  }
  to {
    transform: translate(75.32545vw, 100vh) scale(0.055);
  }
}
.snow:nth-child(148) {
  opacity: 0.5295;
  transform: translate(59.3846vw, -10px) scale(0.4253);
  animation: fall-148 18s -19s linear infinite;
}
@keyframes fall-148 {
  39.105% {
    transform: translate(63.3943vw, 39.105vh) scale(0.4253);
  }
  to {
    transform: translate(61.38945vw, 100vh) scale(0.4253);
  }
}
.snow:nth-child(149) {
  opacity: 0.0852;
  transform: translate(78.5135vw, -10px) scale(0.4727);
  animation: fall-149 18s -10s linear infinite;
}
@keyframes fall-149 {
  59.271% {
    transform: translate(88.0374vw, 59.271vh) scale(0.4727);
  }
  to {
    transform: translate(83.27545vw, 100vh) scale(0.4727);
  }
}
.snow:nth-child(150) {
  opacity: 0.3017;
  transform: translate(11.9656vw, -10px) scale(0.2331);
  animation: fall-150 24s -15s linear infinite;
}
@keyframes fall-150 {
  74.222% {
    transform: translate(19.7452vw, 74.222vh) scale(0.2331);
  }
  to {
    transform: translate(15.8554vw, 100vh) scale(0.2331);
  }
}
.snow:nth-child(151) {
  opacity: 0.218;
  transform: translate(13.5983vw, -10px) scale(0.2763);
  animation: fall-151 12s -26s linear infinite;
}
@keyframes fall-151 {
  34.562% {
    transform: translate(6.2012vw, 34.562vh) scale(0.2763);
  }
  to {
    transform: translate(9.89975vw, 100vh) scale(0.2763);
  }
}
.snow:nth-child(152) {
  opacity: 0.4851;
  transform: translate(81.9393vw, -10px) scale(0.8404);
  animation: fall-152 23s -27s linear infinite;
}
@keyframes fall-152 {
  35.82% {
    transform: translate(82.6344vw, 35.82vh) scale(0.8404);
  }
  to {
    transform: translate(82.28685vw, 100vh) scale(0.8404);
  }
}
.snow:nth-child(153) {
  opacity: 0.4751;
  transform: translate(73.7305vw, -10px) scale(0.8316);
  animation: fall-153 24s -19s linear infinite;
}
@keyframes fall-153 {
  39.182% {
    transform: translate(81.4327vw, 39.182vh) scale(0.8316);
  }
  to {
    transform: translate(77.5816vw, 100vh) scale(0.8316);
  }
}
.snow:nth-child(154) {
  opacity: 0.2988;
  transform: translate(82.3526vw, -10px) scale(0.0838);
  animation: fall-154 21s -18s linear infinite;
}
@keyframes fall-154 {
  59.311% {
    transform: translate(79.8248vw, 59.311vh) scale(0.0838);
  }
  to {
    transform: translate(81.0887vw, 100vh) scale(0.0838);
  }
}
.snow:nth-child(155) {
  opacity: 0.4078;
  transform: translate(8.2857vw, -10px) scale(0.7126);
  animation: fall-155 11s -6s linear infinite;
}
@keyframes fall-155 {
  58.706% {
    transform: translate(1.5465vw, 58.706vh) scale(0.7126);
  }
  to {
    transform: translate(4.9161vw, 100vh) scale(0.7126);
  }
}
.snow:nth-child(156) {
  opacity: 0.0251;
  transform: translate(30.1296vw, -10px) scale(0.9652);
  animation: fall-156 19s -30s linear infinite;
}
@keyframes fall-156 {
  30.549% {
    transform: translate(38.721vw, 30.549vh) scale(0.9652);
  }
  to {
    transform: translate(34.4253vw, 100vh) scale(0.9652);
  }
}
.snow:nth-child(157) {
  opacity: 0.775;
  transform: translate(35.0258vw, -10px) scale(0.6623);
  animation: fall-157 29s -9s linear infinite;
}
@keyframes fall-157 {
  46.784% {
    transform: translate(40.0417vw, 46.784vh) scale(0.6623);
  }
  to {
    transform: translate(37.53375vw, 100vh) scale(0.6623);
  }
}
.snow:nth-child(158) {
  opacity: 0.4786;
  transform: translate(3.4549vw, -10px) scale(0.4083);
  animation: fall-158 15s -21s linear infinite;
}
@keyframes fall-158 {
  79.678% {
    transform: translate(8.5561vw, 79.678vh) scale(0.4083);
  }
  to {
    transform: translate(6.0055vw, 100vh) scale(0.4083);
  }
}
.snow:nth-child(159) {
  opacity: 0.8631;
  transform: translate(27.4055vw, -10px) scale(0.8855);
  animation: fall-159 25s -28s linear infinite;
}
@keyframes fall-159 {
  78.753% {
    transform: translate(31.6347vw, 78.753vh) scale(0.8855);
  }
  to {
    transform: translate(29.5201vw, 100vh) scale(0.8855);
  }
}
.snow:nth-child(160) {
  opacity: 0.9433;
  transform: translate(42.7564vw, -10px) scale(0.3993);
  animation: fall-160 17s -29s linear infinite;
}
@keyframes fall-160 {
  38.007% {
    transform: translate(51.8608vw, 38.007vh) scale(0.3993);
  }
  to {
    transform: translate(47.3086vw, 100vh) scale(0.3993);
  }
}
.snow:nth-child(161) {
  opacity: 0.8181;
  transform: translate(15.1479vw, -10px) scale(0.7397);
  animation: fall-161 28s -26s linear infinite;
}
@keyframes fall-161 {
  78.885% {
    transform: translate(22.7169vw, 78.885vh) scale(0.7397);
  }
  to {
    transform: translate(18.9324vw, 100vh) scale(0.7397);
  }
}
.snow:nth-child(162) {
  opacity: 0.8662;
  transform: translate(28.4182vw, -10px) scale(0.4004);
  animation: fall-162 18s -3s linear infinite;
}
@keyframes fall-162 {
  64.001% {
    transform: translate(19.0446vw, 64.001vh) scale(0.4004);
  }
  to {
    transform: translate(23.7314vw, 100vh) scale(0.4004);
  }
}
.snow:nth-child(163) {
  opacity: 0.3998;
  transform: translate(42.2699vw, -10px) scale(0.2645);
  animation: fall-163 20s -8s linear infinite;
}
@keyframes fall-163 {
  32.141% {
    transform: translate(33.1233vw, 32.141vh) scale(0.2645);
  }
  to {
    transform: translate(37.6966vw, 100vh) scale(0.2645);
  }
}
.snow:nth-child(164) {
  opacity: 0.0322;
  transform: translate(76.0449vw, -10px) scale(0.9006);
  animation: fall-164 18s -7s linear infinite;
}
@keyframes fall-164 {
  66.018% {
    transform: translate(85.4361vw, 66.018vh) scale(0.9006);
  }
  to {
    transform: translate(80.7405vw, 100vh) scale(0.9006);
  }
}
.snow:nth-child(165) {
  opacity: 0.6722;
  transform: translate(76.4284vw, -10px) scale(0.3327);
  animation: fall-165 12s -12s linear infinite;
}
@keyframes fall-165 {
  33.975% {
    transform: translate(83.8411vw, 33.975vh) scale(0.3327);
  }
  to {
    transform: translate(80.13475vw, 100vh) scale(0.3327);
  }
}
.snow:nth-child(166) {
  opacity: 0.4069;
  transform: translate(68.2596vw, -10px) scale(0.3182);
  animation: fall-166 21s -19s linear infinite;
}
@keyframes fall-166 {
  52.447% {
    transform: translate(75.7112vw, 52.447vh) scale(0.3182);
  }
  to {
    transform: translate(71.9854vw, 100vh) scale(0.3182);
  }
}
.snow:nth-child(167) {
  opacity: 0.9225;
  transform: translate(52.8481vw, -10px) scale(0.4348);
  animation: fall-167 23s -21s linear infinite;
}
@keyframes fall-167 {
  52.716% {
    transform: translate(58.4377vw, 52.716vh) scale(0.4348);
  }
  to {
    transform: translate(55.6429vw, 100vh) scale(0.4348);
  }
}
.snow:nth-child(168) {
  opacity: 0.4886;
  transform: translate(94.8042vw, -10px) scale(0.1437);
  animation: fall-168 11s -22s linear infinite;
}
@keyframes fall-168 {
  78.555% {
    transform: translate(92.5572vw, 78.555vh) scale(0.1437);
  }
  to {
    transform: translate(93.6807vw, 100vh) scale(0.1437);
  }
}
.snow:nth-child(169) {
  opacity: 0.4252;
  transform: translate(67.1608vw, -10px) scale(0.0437);
  animation: fall-169 21s -4s linear infinite;
}
@keyframes fall-169 {
  43.777% {
    transform: translate(60.2346vw, 43.777vh) scale(0.0437);
  }
  to {
    transform: translate(63.6977vw, 100vh) scale(0.0437);
  }
}
.snow:nth-child(170) {
  opacity: 0.7529;
  transform: translate(27.4114vw, -10px) scale(0.7075);
  animation: fall-170 10s -16s linear infinite;
}
@keyframes fall-170 {
  71.383% {
    transform: translate(33.3594vw, 71.383vh) scale(0.7075);
  }
  to {
    transform: translate(30.3854vw, 100vh) scale(0.7075);
  }
}
.snow:nth-child(171) {
  opacity: 0.7518;
  transform: translate(93.8815vw, -10px) scale(0.8101);
  animation: fall-171 29s -22s linear infinite;
}
@keyframes fall-171 {
  66.239% {
    transform: translate(89.1472vw, 66.239vh) scale(0.8101);
  }
  to {
    transform: translate(91.51435vw, 100vh) scale(0.8101);
  }
}
.snow:nth-child(172) {
  opacity: 0.6757;
  transform: translate(63.9814vw, -10px) scale(0.366);
  animation: fall-172 26s -15s linear infinite;
}
@keyframes fall-172 {
  36.481% {
    transform: translate(58.9424vw, 36.481vh) scale(0.366);
  }
  to {
    transform: translate(61.4619vw, 100vh) scale(0.366);
  }
}
.snow:nth-child(173) {
  opacity: 0.8103;
  transform: translate(68.7867vw, -10px) scale(0.5422);
  animation: fall-173 24s -29s linear infinite;
}
@keyframes fall-173 {
  49.688% {
    transform: translate(75.9423vw, 49.688vh) scale(0.5422);
  }
  to {
    transform: translate(72.3645vw, 100vh) scale(0.5422);
  }
}
.snow:nth-child(174) {
  opacity: 0.7961;
  transform: translate(82.5051vw, -10px) scale(0.5653);
  animation: fall-174 18s -29s linear infinite;
}
@keyframes fall-174 {
  32.3% {
    transform: translate(89.1583vw, 32.3vh) scale(0.5653);
  }
  to {
    transform: translate(85.8317vw, 100vh) scale(0.5653);
  }
}
.snow:nth-child(175) {
  opacity: 0.1888;
  transform: translate(79.1541vw, -10px) scale(0.108);
  animation: fall-175 14s -25s linear infinite;
}
@keyframes fall-175 {
  72.37% {
    transform: translate(79.6863vw, 72.37vh) scale(0.108);
  }
  to {
    transform: translate(79.4202vw, 100vh) scale(0.108);
  }
}
.snow:nth-child(176) {
  opacity: 0.2553;
  transform: translate(70.4vw, -10px) scale(0.5086);
  animation: fall-176 23s -23s linear infinite;
}
@keyframes fall-176 {
  54.459% {
    transform: translate(68.0985vw, 54.459vh) scale(0.5086);
  }
  to {
    transform: translate(69.24925vw, 100vh) scale(0.5086);
  }
}
.snow:nth-child(177) {
  opacity: 0.5494;
  transform: translate(14.1387vw, -10px) scale(0.4912);
  animation: fall-177 17s -14s linear infinite;
}
@keyframes fall-177 {
  36.506% {
    transform: translate(14.9979vw, 36.506vh) scale(0.4912);
  }
  to {
    transform: translate(14.5683vw, 100vh) scale(0.4912);
  }
}
.snow:nth-child(178) {
  opacity: 0.9029;
  transform: translate(0.1737vw, -10px) scale(0.9427);
  animation: fall-178 22s -15s linear infinite;
}
@keyframes fall-178 {
  43.47% {
    transform: translate(1.8293vw, 43.47vh) scale(0.9427);
  }
  to {
    transform: translate(1.0015vw, 100vh) scale(0.9427);
  }
}
.snow:nth-child(179) {
  opacity: 0.1659;
  transform: translate(82.6894vw, -10px) scale(0.7814);
  animation: fall-179 20s -21s linear infinite;
}
@keyframes fall-179 {
  46.05% {
    transform: translate(78.7126vw, 46.05vh) scale(0.7814);
  }
  to {
    transform: translate(80.701vw, 100vh) scale(0.7814);
  }
}
.snow:nth-child(180) {
  opacity: 0.9011;
  transform: translate(40.283vw, -10px) scale(0.4886);
  animation: fall-180 26s -28s linear infinite;
}
@keyframes fall-180 {
  60.919% {
    transform: translate(39.267vw, 60.919vh) scale(0.4886);
  }
  to {
    transform: translate(39.775vw, 100vh) scale(0.4886);
  }
}
.snow:nth-child(181) {
  opacity: 0.4049;
  transform: translate(55.9209vw, -10px) scale(0.9231);
  animation: fall-181 24s -17s linear infinite;
}
@keyframes fall-181 {
  78.52% {
    transform: translate(63.5476vw, 78.52vh) scale(0.9231);
  }
  to {
    transform: translate(59.73425vw, 100vh) scale(0.9231);
  }
}
.snow:nth-child(182) {
  opacity: 0.8322;
  transform: translate(50.1173vw, -10px) scale(0.5835);
  animation: fall-182 17s -15s linear infinite;
}
@keyframes fall-182 {
  52.672% {
    transform: translate(52.9955vw, 52.672vh) scale(0.5835);
  }
  to {
    transform: translate(51.5564vw, 100vh) scale(0.5835);
  }
}
.snow:nth-child(183) {
  opacity: 0.96;
  transform: translate(29.6648vw, -10px) scale(0.9515);
  animation: fall-183 28s -30s linear infinite;
}
@keyframes fall-183 {
  31.076% {
    transform: translate(19.8994vw, 31.076vh) scale(0.9515);
  }
  to {
    transform: translate(24.7821vw, 100vh) scale(0.9515);
  }
}
.snow:nth-child(184) {
  opacity: 0.4756;
  transform: translate(20.4921vw, -10px) scale(0.5735);
  animation: fall-184 25s -20s linear infinite;
}
@keyframes fall-184 {
  53.646% {
    transform: translate(19.1431vw, 53.646vh) scale(0.5735);
  }
  to {
    transform: translate(19.8176vw, 100vh) scale(0.5735);
  }
}
.snow:nth-child(185) {
  opacity: 0.466;
  transform: translate(22.1702vw, -10px) scale(1);
  animation: fall-185 28s -3s linear infinite;
}
@keyframes fall-185 {
  45.988% {
    transform: translate(26.6668vw, 45.988vh) scale(1);
  }
  to {
    transform: translate(24.4185vw, 100vh) scale(1);
  }
}
.snow:nth-child(186) {
  opacity: 0.0629;
  transform: translate(58.9241vw, -10px) scale(0.7022);
  animation: fall-186 25s -29s linear infinite;
}
@keyframes fall-186 {
  36.791% {
    transform: translate(64.804vw, 36.791vh) scale(0.7022);
  }
  to {
    transform: translate(61.86405vw, 100vh) scale(0.7022);
  }
}
.snow:nth-child(187) {
  opacity: 0.1546;
  transform: translate(71.2371vw, -10px) scale(0.9395);
  animation: fall-187 19s -24s linear infinite;
}
@keyframes fall-187 {
  66.504% {
    transform: translate(65.8072vw, 66.504vh) scale(0.9395);
  }
  to {
    transform: translate(68.52215vw, 100vh) scale(0.9395);
  }
}
.snow:nth-child(188) {
  opacity: 0.6746;
  transform: translate(88.3041vw, -10px) scale(0.0829);
  animation: fall-188 15s -18s linear infinite;
}
@keyframes fall-188 {
  59.452% {
    transform: translate(85.4211vw, 59.452vh) scale(0.0829);
  }
  to {
    transform: translate(86.8626vw, 100vh) scale(0.0829);
  }
}
.snow:nth-child(189) {
  opacity: 0.0012;
  transform: translate(78.8004vw, -10px) scale(0.5305);
  animation: fall-189 10s -28s linear infinite;
}
@keyframes fall-189 {
  49.724% {
    transform: translate(86.558vw, 49.724vh) scale(0.5305);
  }
  to {
    transform: translate(82.6792vw, 100vh) scale(0.5305);
  }
}
.snow:nth-child(190) {
  opacity: 0.8169;
  transform: translate(43.3458vw, -10px) scale(0.3054);
  animation: fall-190 19s -28s linear infinite;
}
@keyframes fall-190 {
  51.678% {
    transform: translate(52.5729vw, 51.678vh) scale(0.3054);
  }
  to {
    transform: translate(47.95935vw, 100vh) scale(0.3054);
  }
}
.snow:nth-child(191) {
  opacity: 0.4561;
  transform: translate(20.6588vw, -10px) scale(0.4983);
  animation: fall-191 17s -23s linear infinite;
}
@keyframes fall-191 {
  57.686% {
    transform: translate(12.2438vw, 57.686vh) scale(0.4983);
  }
  to {
    transform: translate(16.4513vw, 100vh) scale(0.4983);
  }
}
.snow:nth-child(192) {
  opacity: 0.5831;
  transform: translate(1.5968vw, -10px) scale(0.9257);
  animation: fall-192 22s -16s linear infinite;
}
@keyframes fall-192 {
  33.379% {
    transform: translate(-8.3976vw, 33.379vh) scale(0.9257);
  }
  to {
    transform: translate(-3.4004vw, 100vh) scale(0.9257);
  }
}
.snow:nth-child(193) {
  opacity: 0.4296;
  transform: translate(3.204vw, -10px) scale(0.5616);
  animation: fall-193 29s -19s linear infinite;
}
@keyframes fall-193 {
  74.37% {
    transform: translate(-3.6657vw, 74.37vh) scale(0.5616);
  }
  to {
    transform: translate(-0.23085vw, 100vh) scale(0.5616);
  }
}
.snow:nth-child(194) {
  opacity: 0.0884;
  transform: translate(65.0549vw, -10px) scale(0.6905);
  animation: fall-194 17s -16s linear infinite;
}
@keyframes fall-194 {
  42.992% {
    transform: translate(73.1559vw, 42.992vh) scale(0.6905);
  }
  to {
    transform: translate(69.1054vw, 100vh) scale(0.6905);
  }
}
.snow:nth-child(195) {
  opacity: 0.0581;
  transform: translate(16.4613vw, -10px) scale(0.8084);
  animation: fall-195 18s -4s linear infinite;
}
@keyframes fall-195 {
  78.63% {
    transform: translate(14.5626vw, 78.63vh) scale(0.8084);
  }
  to {
    transform: translate(15.51195vw, 100vh) scale(0.8084);
  }
}
.snow:nth-child(196) {
  opacity: 0.5423;
  transform: translate(41.658vw, -10px) scale(0.9625);
  animation: fall-196 22s -29s linear infinite;
}
@keyframes fall-196 {
  44.601% {
    transform: translate(39.1632vw, 44.601vh) scale(0.9625);
  }
  to {
    transform: translate(40.4106vw, 100vh) scale(0.9625);
  }
}
.snow:nth-child(197) {
  opacity: 0.6326;
  transform: translate(37.9101vw, -10px) scale(0.7329);
  animation: fall-197 14s -2s linear infinite;
}
@keyframes fall-197 {
  63.751% {
    transform: translate(34.977vw, 63.751vh) scale(0.7329);
  }
  to {
    transform: translate(36.44355vw, 100vh) scale(0.7329);
  }
}
.snow:nth-child(198) {
  opacity: 0.4356;
  transform: translate(59.1238vw, -10px) scale(0.3384);
  animation: fall-198 29s -10s linear infinite;
}
@keyframes fall-198 {
  54.557% {
    transform: translate(57.4545vw, 54.557vh) scale(0.3384);
  }
  to {
    transform: translate(58.28915vw, 100vh) scale(0.3384);
  }
}
.snow:nth-child(199) {
  opacity: 0.9672;
  transform: translate(67.769vw, -10px) scale(0.6703);
  animation: fall-199 28s -2s linear infinite;
}
@keyframes fall-199 {
  57.431% {
    transform: translate(62.6001vw, 57.431vh) scale(0.6703);
  }
  to {
    transform: translate(65.18455vw, 100vh) scale(0.6703);
  }
}
.snow:nth-child(200) {
  opacity: 0.7981;
  transform: translate(80.2241vw, -10px) scale(0.2108);
  animation: fall-200 17s -13s linear infinite;
}
@keyframes fall-200 {
  43.908% {
    transform: translate(80.9806vw, 43.908vh) scale(0.2108);
  }
  to {
    transform: translate(80.60235vw, 100vh) scale(0.2108);
  }
}
