.roadmap{
    list-style:none;
    position:absolute;
    margin-bottom:150px;
  }
  .roadmap:before{
    position:absolute;
    content:'';
    width:3px;
    height:100%;
    left:50%;
    background:#ee5253;
  }
  .roadmap li{
    width:50%;
    clear:both;
  }
  .roadmap li:nth-child(odd){
    float:left;
    text-align:right;
  }
  .roadmap li:nth-child(even){
    float:right;
    text-align:left;
  }
  
  .roadmap li:nth-child(odd) div{
    margin:20px;
    padding:20px;
    background:#0abde3;
    border-radius:5px;
    box-shadow:0px 10px 15px -10px #000;
  }
  .roadmap li:nth-child(even) div{
    margin:20px;
    padding:20px;
    background:#feca57;
    border-radius:5px;
    box-shadow:0px 10px 15px -10px #000;
  }
  .roadmap li:nth-child(odd):before{
    content:'';
    position:absolute;
    width:20px;
    height:20px;
    background:#0abde3;
    margin-top:20px;
    border-radius:50%;
    margin-left:-8px;
  }
  
  .roadmap li:nth-child(even):before{
    content:'';
    position:absolute;
    width:20px;
    height:20px;
    background:#feca57;
    margin-top:20px;
    border-radius:50%;
    margin-left:-8px;
  }
  